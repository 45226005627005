import React from 'react'
import img1 from '../../assets/img/Homepage/2148422468.jpg'
import img2 from '../../assets/img/Homepage/1636.jpg'
import img3 from '../../assets/img/Homepage/6533.jpg'
export default function Section3() {
  return (
    <div>
        <section className="bg-[#0f172a] ">
	<div className="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
		
		<div className="grid lg:gap-8 lg:grid-cols-2 place-items-center lg:items-center">
			<div>
			<h3 className="text-2xl font-bold tracki text-white sm:text-3xl ">Benefits of Publishing</h3>
				
				
				<div className="mt-12 space-y-12">
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12  rounded-md bg-blue-600 ">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
						<h4 className="text-lg font-medium leadi text-white">Industry Recognition</h4>
							<p className="mt-2 text-gray-300 md:text-lg">Position your organization as a pioneer in shaping the standards of excellence.</p>
							
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md bg-blue-600 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
						<h4 className="text-lg font-medium leadi text-white">Impactful Contribution</h4>
							<p className="mt-2 text-gray-300 md:text-lg">Aid organizations worldwide in navigating their transformation challenges.</p>
							
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md bg-blue-600 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
						<h4 className="text-lg font-medium leadi text-white">Revenue Opportunities</h4>
							<p className="mt-2 text-gray-300 md:text-lg">Choose to offer your frameworks for free or as paid content, creating a new revenue stream.</p>
							
						</div>
					</div>
				</div>
			</div>
			<div aria-hidden="true" className="mt-10 lg:mt-0">
				<img src={img1} alt="" className="mx-auto w-96 rounded-lg shadow-lg " />
			</div>
		</div>
		<div>
			<div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
				<div className="lg:col-start-2">
				<h3 className="text-2xl font-bold tracki sm:text-3xl text-white">Showcase Your Expertise</h3>
					
					<div className="mt-12 space-y-12">
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-12 h-12 rounded-md bg-blue-600 dark:text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" className="w-7 h-7">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
							<h4 className="text-lg font-medium leadi text-white">Wide Exposure</h4>
							<p className="mt-2 text-gray-300 md:text-lg">Share your organization’s proprietary maturity frameworks with a vast community of professionals and organizations actively seeking to enhance their transformation journeys.</p>
							
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-12 h-12 rounded-md bg-blue-600 dark:text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" className="w-7 h-7">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
							<h4 className="text-lg font-medium leadi text-white">Diverse Categories</h4>
							<p className="mt-2 text-gray-300 md:text-lg">Whether your expertise lies in technology, business processes, or organizational culture, Spectra Store is your platform to reach an audience eager for knowledge in these areas.</p>
							
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-12 h-12 rounded-md bg-blue-600 dark:text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" className="w-7 h-7">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
							<h4 className="text-lg font-medium leadi text-white">Monetization Opportunity</h4>
							<p className="mt-2 text-gray-300 md:text-lg">Choose to offer your frameworks for free to build your reputation or monetize them to generate revenue. Spectra Store supports both models, providing flexibility in how you share your insights.</p>
							
							</div>
						</div>
						
					</div>
				</div>
				<div className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
					<img src={img2} alt="" className="mx-auto w-96 rounded-lg shadow-lg dark:bg-gray-500" />
				</div>
			</div>
		</div>
		<div className="grid lg:gap-8 lg:grid-cols-2 place-items-center lg:items-center">
			<div>
			<h3 className="text-2xl font-bold tracki sm:text-3xl text-white">Seamless Integration and Accessibility</h3>
				
				
				<div className="mt-12 space-y-12">
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md bg-blue-600 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
						<h4 className="text-lg font-medium leadi text-white">No Sign-in Required for Consumers</h4>
							<p className="mt-2 text-gray-300 md:text-lg">Accessing maturity frameworks in Spectra Store does not require a separate sign-in for Spectrum 360 users. Our integrated platform ensures you have immediate access to the resources you need, when you need them.</p>
							
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md bg-blue-600 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
						<h4 className="text-lg font-medium leadi text-white">Variety of Frameworks</h4>
							<p className="mt-2 text-gray-300 md:text-lg">From free insights to premium frameworks, Spectra Store offers a diverse catalogue tailored to a wide range of transformational needs. Whether you're a startup or a global enterprise, find the frameworks that resonate with your objectives.</p>
							
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md bg-blue-600 text-white">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
						<h4 className="text-lg font-medium leadi text-white">Enhance Your Transformation Strategy</h4>
							<p className="mt-2 text-gray-300 md:text-lg">Utilize these frameworks to benchmark your organization’s performance against the best in the industry. With Spectra Store, inspiration and strategic direction are always at your fingertips, enabling you to make informed decisions that propel your organization forward.</p>
							
						</div>
					</div>
				</div>
			</div>
			<div aria-hidden="true" className="mt-10 lg:mt-0">
				<img src={img3} alt="" className="mx-auto w-96 rounded-lg shadow-lg " />
			</div>
		</div>
	</div>
</section>
    </div>
  )
}
