import { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import Modal from "../pages/Modal/Modal";
import logo from "../assets/img/spectra-store.png";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  // Modal

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  // Sticky Header
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const isScrolled = scrollPosition > 50;

      setIsSticky(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToAboutSection = () => {
    const aboutSectionRef = document.getElementById("aboutSection");

    aboutSectionRef.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToFeatureSection = () => {
    const aboutSectionRef = document.getElementById("featureSection");

    aboutSectionRef.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToPricingSection = () => {
    const aboutSectionRef = document.getElementById("pricingSection");

    aboutSectionRef.scrollIntoView({ behavior: "smooth" });
  };

  const headerClass = isSticky
    ? "fixed top-0 left-0 w-full  shadow-md z-50  transition-all duration-300 ease-in-out transform translate-y-0 "
    : "";

  return (
    <div>
      <div className={headerClass}>
        <div class="bg-gray-900 ">
          <div class="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
            <div class="relative flex items-center justify-between">
              <a href="/" class="inline-flex items-center">
                <img src={logo} width={42}></img>
                <span class="ml-2  text-sm lg:text-2xl font-bold  text-gray-100 uppercase">
                  Spectra Store
                </span>
              </a>
              <ul class="flex items-center hidden space-x-8 lg:flex">
                <li>
                  <a
                    onClick={scrollToAboutSection}
                    class="font-medium cursor-pointer link link-underline link-underline-black tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a onClick={scrollToFeatureSection} class="font-medium cursor-pointer link link-underline link-underline-black tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400">
                    Features
                  </a>
                </li>{" "}
                <li>
                  <a  onClick={scrollToPricingSection} class="font-medium cursor-pointer link link-underline link-underline-black tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400">
                    Pricing
                  </a>
                </li>
                <li>
                  <button
                    
                    className="inline-flex  items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded-3xl shadow-md bg-blue-600 hover:bg-blue-500 focus:shadow-outline focus:outline-none"
                    title="Login"
                  >
                   Login
                  </button>
                </li>
              </ul>

              <div class="lg:hidden">
                <button
                  aria-label="Open Menu"
                  title="Open Menu"
                  class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => setIsMenuOpen(true)}
                >
                  <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                    />
                    <path
                      fill="currentColor"
                      d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                    />
                    <path
                      fill="currentColor"
                      d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                    />
                  </svg>
                </button>
                {isMenuOpen && (
                  <div class="absolute top-0 left-0 w-full z-10 ">
                    <div class="p-5 bg-gray-900 border border-slate-800 rounded shadow-sm">
                      <div class="flex items-center justify-between mb-4">
                        <div>
                          <a href="/" class="inline-flex items-center">
                            <img src={logo} width={42}></img>
                            <span class="ml-2 text-[16px] lg:text-['20px'] font-bold  text-gray-100 uppercase">
                              Spectra Store
                            </span>
                          </a>
                        </div>
                        <div>
                          <button
                            aria-label="Close Menu"
                            title="Close Menu"
                            class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                              <path
                                fill="currentColor"
                                d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <nav>
                        <ul class="space-y-2 ">
                          {" "}
                          <li>
                            <a onClick={scrollToAboutSection} class="font-normal cursor-pointer  text-lg tracking-wide p-2 text-gray-100 transition-colors duration-200 hover:text-[#2563eb]">
                              About
                            </a>
                          </li>{" "}
                          <li>
                            <a onClick={scrollToFeatureSection} class="font-normal cursor-pointer text-lg tracking-wide p-2 text-gray-100 transition-colors duration-200 hover:text-[#2563eb]">
                              Features
                            </a>
                          </li>
                          <li>
                            <a onClick={scrollToPricingSection} class="font-normal cursor-pointer text-lg tracking-wide p-2 text-gray-100 transition-colors duration-200 hover:text-[#2563eb]">
                              Pricing
                            </a>
                          </li>
                          <li>
                            <li>
                              <a  class="inline-flex items-center justify-center cursor-pointer h-10 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-[#2563eb] hover:bg-blue-400 hover:text-slate-800 focus:shadow-outline focus:outline-none">
                                Login
                              </a>
                            </li>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
}
