import React,{useEffect} from 'react'
import { Route, Routes, useLocation } from "react-router-dom";
import Homepage from './pages/Homepage'

import HeaderComponent from './components/header'
import Footer from './components/footer'
export default function App() {
  const location = useLocation();
  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);
   // Scroll Top
   const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
   <div className="App">
    <HeaderComponent/>
    <Routes>
      <Route path="/" Component={Homepage} />
      
      </Routes>
     <div className="fixed bottom-8 right-8 z-50">
        <button
          onClick={scrollToTop}
          className="bg-[#2563eb] text-white rounded-full p-3 hover:bg-[#596191] focus:outline-none focus:shadow-outline"
          aria-label="Scroll to top"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 10l7-7m0 0l7 7m-7-7v18"
            ></path>
          </svg>
        </button>
      </div>
    <Footer/>
    </div>
  )
}
