import React, { useEffect, useRef, useState } from "react";
import Modal from '../../pages/Modal/Modal'

export default function LastSection() {
    const [isModalOpen, setModalOpen] = useState(false);
    // Modal
     
    const openModal = () => {
      setModalOpen(true);
    };
    const closeModal = () => {
      setModalOpen(false);
    };
  return (
    <div className=" bg-[#0f172a]">
         <Modal isOpen={isModalOpen} closeModal={closeModal} />
       <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 ">

<div
    class="relative isolate overflow-hidden  px-6 py-20 text-center sm:rounded-3xl   sm:px-16 sm:shadow-sm">

    <h2 class="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">
    Join <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-blue-300 to-gray-100">Spectra Store</span> Today
    </h2>

    <h3 class="mx-auto mt-6 max-w-4xl text-lg leading-8 text-gray-300">
    To become a publisher, visit the Spectra Store publisher portal and choose the plan that best suits your organization's size and ambitions. Our team is ready to support you through the registration process, framework submission, and beyond, ensuring your success in the Spectra Store ecosystem.

Empower your organization and share your expertise on a global stage with Spectra Store's Publisher License Plans.
    </h3>

    <div class="mt-8 flex items-center justify-center gap-x-6">
        <a class="inline-flex cursor-pointer items-center justify-center gap-2 rounded-xl bg-blue-600 px-4 py-3 text-sm font-semibold text-white shadow-sm transition-all duration-150 hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={openModal}>
            Reach Us Today
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"></path>
            </svg>
        </a>
    </div>

    {/* <!-- gradient svg --> */}
    <svg viewBox="0 0 1024 1024"
        class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
        aria-hidden="true">
        <circle cx="512" cy="512" r="512" fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fill-opacity="0.7">
        </circle>
        <defs>
            <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                <stop stop-color="#3b82f6"></stop>
                <stop offset="1" stop-color="#1d4ed8"></stop>
            </radialGradient>
        </defs>
    </svg>

</div>

</div>
    </div>
  )
}
