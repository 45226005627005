import { React, useState, useEffect, useRef } from "react";
import Modal from "../../pages/Modal/Modal";
import checkgrey_img from "../../assets/img/check-grey.svg";
import checkwhite_img from "../../assets/img/check-white.svg";
import arrow_img from "../../assets/img/arrow-right.svg";
import img1 from "../../assets/img/abstract-1.jpg";
import img2 from "../../assets/img/abstract-2.jpg";
import img3 from "../../assets/img/abstract-3.jpg";

export default function Index() {
  const [isModalOpen, setModalOpen] = useState(false);
  // const [currencySymbol, setCurrencySymbol] = useState("$"); // currency symbol
  // const pricingRef = useRef(null);
  // const prices = {
  //   basic: 999,
  //   professional: 2499,
  //   premium: 4999
  // };

  // Modal
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  // useEffect(() => {
  //   // Fetch currency symbol
  //   fetch("https://api.ipdata.co?api-key=d060a3127d7aa392230c21560791a6986f30fd24b1cdffbc24749657")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const currencySymbol = data.currency.native;
  //       setCurrencySymbol(currencySymbol);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching user's location:", error);
  //     });
  // }, []);

  return (
    <div id="pricingSection" >
      <div class="lg:py-5 text-white bg-[#0f172a]">
        <main class="max-w-7xl mx-auto pt-10 pb-28 px-8">
          <div class="max-w-4xl mx-auto mb-14 text-center">
            <h1 class="text-4xl font-semibold mb-6 lg:text-5xl">
              Spectra Store{" "}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-blue-300 to-gray-100">
                License Plans
              </span>
            </h1>
            <p class="text-lg text-gray-300 ">
              At Spectra Store, we empower organizations to share their maturity
              frameworks with a global audience. Our Publisher License Plans are
              designed to accommodate the diverse needs of content creators,
              from independent consultants to large service organizations. Each
              plan offers a unique set of features tailored to maximize your
              visibility, impact, and revenue potential.
            </p>
          </div>

          <div class="flex flex-col justify-between items-center lg:flex-row lg:items-start">
            <div class="w-full flex-1 mt-8 p-8 order-1 bg-white shadow-xl rounded-3xl sm:w-96 lg:w-full lg:rounded-r-none">
              <div class="mb-2 pb-7 flex items-center border-b border-gray-300">
                <img src={img1} alt="" class="rounded-3xl w-20 h-20" />
                <div class="ml-5">
                  <span class="block text-lg lg:text-2xl text-black font-semibold">
                    Basic
                  </span>
                  <span>
                    <span class="font-medium text-gray-500 text-xl align-top">
                      $&thinsp;
                    </span>
                    <span class="text-lg lg:text-3xl text-black font-bold">
                      499
                    </span>

                    
                  </span>
                  <span class="text-gray-500 font-medium">
                    / year
                  </span>
                  <p className="text-gray-600">
                  + revenue share on sales (70% Publisher + 30% AeroAegis)
                  </p>
                </div>
              </div>
              <p className="text-black font-semibold py-2">
                New entrants looking to share their initial frameworks.
              </p>
              <ul class="mb-7 font-medium text-gray-500">
                <li class="flex text-lg mb-2">
                  <img src={checkgrey_img} alt="" />
                  <span class="ml-3">Publish up to 3 maturity frameworks</span>
                </li>
                <li class="flex text-lg mb-2 md:mb-28">
                  <img src={checkgrey_img} alt="" />
                  <span class="ml-3">Standard visibility in the store</span>
                </li>
              </ul>
              <a
                onClick={openModal}
                className="flex justify-center cursor-pointer items-center bg-indigo-600 hover:bg-indigo-500 rounded-xl py-5 px-4 text-center text-white text-xl"
              >
                Subscribe
                <img src={arrow_img} class="ml-2" alt="" />
              </a>
            </div>

            <div class="w-full flex-1 mt-8 p-8 order-2 shadow-xl rounded-3xl bg-gray-800 text-gray-400 sm:w-96 lg:w-full lg:order-2 lg:mt-0">
              <div class="mb-2 pb-8 flex items-center border-b border-gray-600">
                <img src={img2} alt="" class="rounded-3xl w-20 h-20" />
                <div class="ml-5">
                  <span class="block text-lg lg:text-3xl font-semibold text-white">
                    Enterprise
                  </span>
                  <span>
                    <span class="font-medium text-gray-500 text-xl align-top">
                      $&thinsp;
                    </span>
                    <span class="text-lg lg:text-3xl font-bold text-white">
                      2999
                    </span>
                  </span>
                  <span class="font-medium text-white">
                    / year
                  </span>
                  <p className="text-gray-300">
                  + optimized revenue share on sales (90% Publisher + 10% AeroAegis)
                  </p>
                  
                </div>
              </div>
              <p className="text-white font-semibold py-2">
                Industry leaders looking to establish thought leadership and
                dominate the marketplace.
              </p>
              <ul class="mb-10 font-medium text-xl text-white">
                <li class="flex mb-6 md:mb-[100px]">
                  <img src={checkwhite_img} alt="" />
                  <span class="ml-3">
                    All Professional Plan features plus strategic account
                    management and premium marketplace positioning.
                  </span>
                </li>
              </ul>
              <a
                onClick={openModal}
                class="flex justify-center cursor-pointer items-center bg-indigo-600 hover:bg-indigo-500 rounded-xl py-6 px-4 text-center text-white text-2xl"
              >
                Subscribe
                <img src={arrow_img} class="ml-2" alt="" />
              </a>
            </div>

            <div class="w-full  flex-1 mt-8 p-8 order-4 bg-white shadow-xl  rounded-3xl sm:w-96 lg:w-full lg:order-4 lg:rounded-l-none">
              <div class="mb-2 pb-7 flex items-center border-b border-gray-300">
                <img src={img3} alt="" class="rounded-3xl w-20 h-20" />
                <div class="ml-5">
                  <span class="block text-lg lg:text-3xl font-semibold text-black">
                    Professional
                  </span>
                  <span>
                    <span class="font-medium text-xl text-gray-500 align-top">
                      $&thinsp;
                    </span>
                    <span class="text-lg lg:text-3xl font-bold text-black">
                      1499
                    </span>
                  </span>
                  <span class="font-medium text-gray-500">
                    / year
                  </span>
                  <p className="text-gray-600">
                  + reduced revenue share on sales (80% Publisher + 20% AeroAegis)
                  </p>
                  
                </div>
              </div>
              <p className="text-black font-semibold py-2">
                Organizations aiming to actively engage with the community and
                sell multiple frameworks.
              </p>
              <ul class="mb-11 font-medium text-gray-500 text-xl">
                <li class="flex mb-6">
                  <img src={checkgrey_img} alt="" />
                  <span class="ml-3">Publish unlimited frameworks</span>
                </li>
                <li class="flex mb-6">
                  <img src={checkgrey_img} alt="" />
                  <span class="ml-3">Enhanced visibility</span>
                </li>
                <li class="flex mb-6">
                  <img src={checkgrey_img} alt="" />
                  <span class="ml-3">Promotional support</span>
                </li>
              </ul>
              <a
                onClick={openModal}
                class="flex justify-center cursor-pointer items-center bg-indigo-600 hover:bg-indigo-500 rounded-xl py-5 px-4 text-center text-white text-xl"
              >
                Subscribe
                <img src={arrow_img} class="ml-2" alt="" />
              </a>
            </div>
          </div>
        </main>
      </div>
      {/* Modal */}
      <Modal isOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
}
