import React, { useRef } from "react";
import img1 from "../../assets/img/Homepage/profile.png";
import img2 from "../../assets/img/Homepage/filter.png";
import img3 from "../../assets/img/Homepage/icons8-collaboration-96.png";
import img4 from "../../assets/img/Homepage/brainstorming.png";
import img5 from "../../assets/img/Homepage/videoconference.png";

const Comprehensive = () => {
  const featureRef = useRef(null);
  return (
    <div
      id="featureSection"
      ref={featureRef}
      class="bg-[#0f172a] py-12 lg:py-20"
    >
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-5xl lg:text-center flex flex-col justify-center items-center">
          <h2 class="text-base font-semibold leading-7 text-blue-100 bg-red-400 px-3 rounded-xl uppercase mb-4 lg:mb-8">
            For Framework Publishers
          </h2>
          <h1 class="lg:text-5xl text-4xl md:text-5xl font-bold tracking-tight text-gray-100 text-center">
            <span class="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-blue-300 to-gray-100">
              Elevate Your Influence. Share Your Expertise.
            </span>
          </h1>

          <p class="mt-6 text-md lg:text-lg text-gray-300 max-w-4xl text-center">
            Spectra Store invites organizations with proven methodologies and
            success stories to publish their maturity frameworks. This unique
            opportunity allows you to showcase your expertise, contribute to the
            broader business and technology communities, and establish your
            organization as a thought leader in your practice area. Whether your
            framework addresses the intricacies of digital transformation,
            operational efficiency, or strategic innovation, Spectra Store is
            where your insights reach a global audience.
            
          </p>
        
        </div>
        
      </div>
    </div>
  );
};

export default Comprehensive;
