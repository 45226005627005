import Logo1 from '../../assets/img/features/digital-transformation.png';
import Logo2 from '../../assets/img/features/expertise.png';
import Logo3 from '../../assets/img/features/recommendation.png';
import Logo4 from '../../assets/img/features/idea-exchange.png';
import Logo5 from '../../assets/img/features/quality-assurance.png';

const Features = () => {
  return (
    <section className="bg-[#0f172a] ">
    <div className="container px-6 py-10 mx-auto">
    <h2 class="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl text-center mb-9">
    Core Features  <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-blue-300 to-gray-100">of Spectra </span> Store
    </h2>

        {/* <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nostrum quam voluptatibus
        </p> */}
        {/* grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-3 */}

        <div className="flex flex-wrap justify-center gap-8 mt-20">
            <div className="p-8 w-96 space-y-3 border-2 border-blue-300 shadow-xl hover:shadow-blue222 rounded-xl ">
                <img src={Logo1} alt="" className='w-16 mx-auto'  />

                <h1 className="text-xl font-semibold  capitalize text-red-400">Diverse Repository of Maturity Frameworks</h1>

                <p className=" font-medium text-gray-300"><span className="mr-1 text-blue-400">Goal:</span>
                To offer a wide range of frameworks covering various domains such as digital transformation, operational efficiency, and strategic innovation, among others.
                </p>
                <p className=" font-medium text-gray-300 py-6"><span className="mr-1  text-blue-400">Benefit:</span>
                Enables consumers to find and adopt frameworks that closely align with their specific needs and challenges.
                </p>

                
            </div>

            <div className="w-96 p-8 space-y-3 border-2  shadow-xl hover:shadow-blue222  border-blue-300 rounded-xl">
            <img src={Logo2} alt="" className='w-16 mx-auto'  />

                <h1 className="text-xl font-semibold  capitalize text-red-400 ">Showcase of Expertise</h1>

                <p className=" font-medium text-gray-300 py-6"><span className="mr-1 text-blue-400">Goal:</span>
                To provide a platform for publishers to highlight their methodologies, case studies, and the impact of their frameworks in real-world scenarios.
                </p>
                <p className=" text-gray-300 font-medium"><span className="mr-1  text-blue-400">Benefit:</span>
                Helps publishers establish thought leadership and credibility, while allowing consumers to make informed decisions based on proven success.
                </p>

                
            </div>
            <div className="w-96 p-8 space-y-3 border-2 border-blue-300 rounded-xl shadow-xl hover:shadow-blue222">
            <img src={Logo3} alt="" className='w-16 mx-auto'  />

                <h1 className="text-xl font-semibold  capitalize text-red-400">Global Reach and Visibility</h1>

                <p className=" font-medium text-gray-300 py-6"><span className="mr-1  text-blue-400">Goal:</span>
                To ensure that published frameworks are accessible to a global audience, increasing the visibility of publishers’ expertise beyond their immediate network.
                </p>
                <p className=" font-medium text-gray-300"><span className="mr-1  text-blue-400">Benefit:</span>
                Facilitates knowledge sharing and innovation spread across industries and geographical boundaries.
                </p>

                
            </div>
            <div className="w-96 p-8 space-y-3 border-2 border-blue-300 rounded-xl shadow-xl hover:shadow-blue222">
            <img src={Logo4} alt="" className='w-16 mx-auto'  />

                <h1 className="text-xl font-semibold  capitalize text-red-400">Tailored Recommendations and Search</h1>

                <p className=" font-medium text-gray-300"><span className="mr-1 text-blue-400">Goal:</span>
                To implement advanced search functionalities and AI-driven recommendations that match consumers with the most relevant frameworks based on their industry, size, and maturity goals.
                </p>
                <p className="text-gray-300 font-medium py-5"><span className="mr-1 text-blue-400">Benefit:</span>
                Enhances user experience by simplifying the discovery process, making it easier for organizations to find the guidance they need.
                </p>

                
            </div>
            <div className="w-96  p-8 space-y-3 border-2 border-blue-300 rounded-xl  shadow-xl hover:shadow-blue222">
            <img src={Logo5} alt="" className='w-16 mx-auto'  />

                <h1 className="text-xl font-semibold  capitalize text-red-400">Quality Assurance and Curated Content</h1>

                <p className="font-medium text-gray-300"><span className="mr-1 text-blue-400">Goal:</span>
                To establish a rigorous vetting process for frameworks published on Spectra Store, ensuring they meet high-quality standards and deliver real value.
                </p>
                <p className=" text-gray-300 font-medium py-6"><span className="mr-1 text-blue-400">Benefit:</span>
                Maintains the trust and integrity of the marketplace, ensuring consumers have access to only the best and most effective frameworks.
                </p>

                
            </div>
        </div>
    </div>
</section>
  )
}

export default Features