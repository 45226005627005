import React from 'react'
import img1 from '../../assets/img/Homepage/22431.jpg'
export default function Experience() {
  return (
    <div>
                <div class="relative group ">
  <img
    class="object-cover w-full lg:h-96 h-[380px]"
    src={img1}
    alt=""
  />
  <div class="absolute top-1/2 left-1/2 bg-black bg-opacity-55 w-full h-full  text-center transform -translate-x-1/2 -translate-y-1/2 text-white  font-bold transition duration-300 opacity-100 group-hover:opacity-0  hover:bg-opacity-100">
  <p className=' text-center text-lg md:text-4xl lg:text-6xl  my-36 md:my-36 lg:my-36'>Experience Transformation with Confidence</p>
  </div>
  <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 transition duration-700 -translate-y-1/2  opacity-0 w-full h-full group-hover:opacity-100 bg-black bg-opacity-80  ">
    <p className='absolute text-center  text-[11px] sm:text-lg md:text-lg lg:text-xl text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
    Spectra Store is more than just a marketplace; it's a pivotal component of the Spectrum 360 ecosystem designed to empower your organization's growth and development. By bridging the gap between knowledge seekers and knowledge providers, we facilitate a dynamic environment where transformation is not just envisioned but realized.
<br/>
<span className=''>Join Spectra Store today to explore, share, and apply the world's most effective maturity frameworks. Whether you aim to publish your insights or elevate your organization's maturity, Spectra Store is your partner in transformation.</span>
    </p>
  </div>
</div>
    </div>
  )
}
