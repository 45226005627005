import React from 'react';

const TargetAud = () => {
  return (
    <>
    <div className='bg-[#0f172a] dark:bg-[#0f172a] '>
    <h2 class="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-gray-100   sm:text-4xl text-center">
    <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-blue-300 to-gray-100">Target</span> Audience
    </h2>
    </div>
    
    <section className="bg-[#0f172a] py-9 dark:bg-[#0f172a] lg:py-12 lg:flex lg:justify-center">
    
      <div className="overflow-hidden  bg-[#0f172a] dark:bg-[#0f172a] lg:mx-8 lg:flex lg:max-w-6xl lg:w-full lg:shadow-md lg:rounded-xl">
        <div className="lg:w-1/2">
          <div className="h-64 bg-cover lg:h-full rounded-xl" style={{backgroundImage: "url('https://images.unsplash.com/photo-1542744173-8e7e53415bb0?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8bWVldGluZ3xlbnwwfHwwfHx8Mg%3D%3D')"}}></div>
        </div>
        <div className="max-w-xl px-6 py-12 lg:max-w-5xl lg:w-1/2 bg-[#0f172a] dark:bg-[#0f172a]">
          <h2 className="text-2xl font-semibold text-white md:text-3xl">
          	Framework <span className="text-blue-500">Publishers</span>
          </h2>
          <p className="mt-4 text-gray-300 ">
          Organizations and thought leaders with proven methodologies and innovative approaches to business and technology transformation. These entities are looking to share their success stories, best practices, and frameworks with a broader audience, thereby establishing themselves as leaders in their fields.
          </p>
          
        </div>
      </div>
      
      
    </section>
    {/* --------------- */}
    <section className="bg-[#0f172a]  lg:py-12 lg:flex lg:justify-center">
      <div className="overflow-hidden bg-[#0f172a] lg:mx-8 lg:flex lg:max-w-6xl lg:w-full lg:shadow-md lg:rounded-xl">
        
        <div className="max-w-xl px-6 py-12 lg:max-w-5xl lg:w-1/2 bg-[#0f172a]">
          <h2 className="text-2xl font-semibold text-white md:text-3xl">
          Framework <span className="text-blue-500">Consumers</span>
          </h2>
          <p className="mt-4 text-gray-300">
          Enterprises, SMBs, and startups seeking to accelerate their maturity journey by leveraging industry-leading practices. These organizations are on the lookout for comprehensive frameworks that can guide their transformation initiatives, offering a shortcut to the trial-and-error process of organizational change.
          </p>
          
        </div>
        <div className="lg:w-1/2">
          <div className="h-64 bg-cover lg:h-full rounded-xl" style={{backgroundImage: "url('https://images.unsplash.com/photo-1552664730-d307ca884978?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDR8fHxlbnwwfHx8fHw%3D')"}}></div>
        </div>
      </div>
      
      
    </section>
    </>
  );
}

export default TargetAud;



