import React from 'react'
import img1 from '../../assets/img/Homepage/verify.png'
import img2 from '../../assets/img/Homepage/money.png'
import img3 from '../../assets/img/Homepage/submit.png'
import img4 from '../../assets/img/Homepage/send.png'
export default function StepsSection() {
  return (
    <div className='bg-[#0f172a]'>
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
 <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
   <div>
     <p className="inline-block px-3 py-1 mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-400">
      
     </p>
   </div>
   <h2 className="max-w-2xl mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
   Publisher <span className='text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-blue-300 to-gray-100'>Registration and Licensing</span>
   </h2>
   <p className="text-base text-gray-100 md:text-lg">
        To ensure quality and relevance, publishers are required to register and may be subject to a licensing fee. This process helps maintain a high standard of contributions and fosters a valuable resource pool for all users of Spectrum 360. Registration is straightforward, aiming to create a vibrant ecosystem of knowledge sharing.
        </p>
 </div>
 <div className="grid gap-8 row-gap-0 lg:grid-cols-4">
   <div className="relative text-center">
     <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-20 sm:h-20">
      <img src={img1} className='w-10 lg:w-12'/>
     </div>
     <h6 className="mb-2 text-2xl text-white font-bold">Registration</h6>
     <p className="max-w-md mb-3 text-sm md:text-lg text-gray-300 sm:mx-auto">
     Begin by registering your organization on Spectra Hub. This initial step ensures you gain access to publish within Spectra Store.
     </p>
    
     <div className="top-0 right-0 flex items-center justify-center h-24 lg:-mr-8 lg:absolute">
       <svg
         className="w-8 text-gray-700 transform rotate-90 lg:rotate-0"
         stroke="currentColor"
         strokeWidth="2"
         strokeLinecap="round"
         strokeLinejoin="round"
         viewBox="0 0 24 24"
       >
         <line
           fill="none"
           strokeMiterlimit="10"
           x1="2"
           y1="12"
           x2="22"
           y2="12"
         />
         <polyline
           fill="none"
           strokeMiterlimit="10"
           points="15,5 22,12 15,19 "
         />
       </svg>
     </div>
   </div>
   <div className="relative text-center">
     <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-20 sm:h-20">
     <img src={img2} className='w-10 lg:w-12'/>
     </div>
     <h6 className="mb-2 text-2xl text-white font-bold">License Fee</h6>
     <p className="max-w-md mb-3 text-sm md:text-lg text-gray-300 sm:mx-auto">
     A transparent license fee structure is applicable for publishers, designed to accommodate organizations of all sizes.
     </p>
    
     <div className="top-0 right-0 flex items-center justify-center h-24 lg:-mr-8 lg:absolute">
       <svg
         className="w-8 text-gray-700 transform rotate-90 lg:rotate-0"
         stroke="currentColor"
         strokeWidth="2"
         strokeLinecap="round"
         strokeLinejoin="round"
         viewBox="0 0 24 24"
       >
         <line
           fill="none"
           strokeMiterlimit="10"
           x1="2"
           y1="12"
           x2="22"
           y2="12"
         />
         <polyline
           fill="none"
           strokeMiterlimit="10"
           points="15,5 22,12 15,19 "
         />
       </svg>
     </div>
   </div>
   <div className="relative text-center">
     <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-20 sm:h-20">
     <img src={img3} className='w-12'/>
     </div>
     <h6 className="mb-2 text-2xl text-white font-bold">Framework Submission</h6>
     <p className="max-w-md mb-3 text-sm md:text-lg text-gray-300 sm:mx-auto">
     Submit your maturity framework for review. Our team will assess its alignment with industry standards and relevance to potential users.
     </p>
    
     <div className="top-0 right-0 flex items-center justify-center h-24 lg:-mr-8 lg:absolute">
       <svg
         className="w-8 text-gray-700 transform rotate-90 lg:rotate-0"
         stroke="currentColor"
         strokeWidth="2"
         strokeLinecap="round"
         strokeLinejoin="round"
         viewBox="0 0 24 24"
       >
         <line
           fill="none"
           strokeMiterlimit="10"
           x1="2"
           y1="12"
           x2="22"
           y2="12"
         />
         <polyline
           fill="none"
           strokeMiterlimit="10"
           points="15,5 22,12 15,19 "
         />
       </svg>
     </div>
   </div>
   <div className="relative text-center">
     <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-20 sm:h-20">
     <img src={img4} className='w-12'/>
     </div>
     <h6 className="mb-2 text-2xl text-white font-bold">Publication</h6>
     <p className="max-w-md mb-3 text-sm md:text-lg text-gray-300 sm:mx-auto">
     Once approved, your framework will be published in Spectra Store, making it available to organizations worldwide.
     </p>
    
   </div>
 </div>
</div>
</div>
  )
}
