import React from "react";

import HeroSection2 from "./HeroSection2";
import LastSection from "./LastSection";

import { Fade, Slide } from "react-awesome-reveal";
import Comprehensive from "./Comprehensive";
import Personalized from "./Personalized";
import Benchmark from "./Benchmark";

import Pricing from '../Pricing/index'
import Transforming from "./Transforming";
import Section4 from "./Section4";
import Paralaxx from "./ParalaxxSection1";
import Section3 from "./Section3";
import StepsSection from "./StepsSection";
import Experience from "./Experience";
import Features from "./Features";
import TargetAud from "./TargetAud";
export default function index() {
  return (
    <div>
      <Fade duration={500} triggerOnce>
     
        <HeroSection2/>
        {/* <HeroSection3 /> */}
      </Fade>
     
      <Comprehensive />
      <TargetAud />
      <Features />
   <Section3/>
      
      {/* <Section4/> */}
      <StepsSection/>
    
     <Pricing/>
     <Experience/>
      <LastSection/>
    </div>
  );
}
